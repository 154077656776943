function Footer() {
    return (
        <div className="page-section page-section--footer">
            <div className="section-content section-content--footer">
                
                <div className="footer custom-widths">
                    
                    <div className="container">
                        
                        <div className="row">
                            <div className="col-sm-12">
                                <img src="/images/public-domain.png" alt="public domain" style={{ maxHeight: '50px', backgroundColor: 'white' }} />
                            </div>
                            <div className="col-lg-3 col-md-5">
                                <div className="social-links">
                                    {/* <a target="_blank" href="https://twitter.com/peopleGameNFT">
                                        <i className="fa fa-twitter" />
                                    </a> */}
                                    
                                </div>
                            </div>
                        </div>
                        
                        
                      
                            {/* <div className="col-lg-5 col-md-7">
                                            <ul id="site-nav-privacy" className="privacy-links">
                                                
                                                <li id="menu-item-470" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-470" style={{ fontFamily: " BrokenConsole-Bold"}}>
                                                    <Link to="/whitepaper">
                                                        Whitepaper
                                                    </Link>
                                                </li>
                                                <li id="menu-item-470" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-470" style={{ fontFamily: " BrokenConsole-Bold" }}>
                                                    <Link to="/tos">
                                                        Terms of Service
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div> */}
                             
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;