
import { Navigate } from "react-router-dom"
import { useEffect } from 'react';

const RedirectPage = () => {
    useEffect(() => {
        window.open("https://medium.com/@thepeoplenftgame/the-people-game-whitepaper-54f808e0e6e9")
    }, []);

    return (
        <Navigate to="/" />
    )
}
export default RedirectPage;