import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import MobileNav from './components/MobileNav';


function Citadel() {
    return (
        <>
            <div id="root">
                <div className="page-wrap">
                   <Header/>
                    <div id="section-1" className="page-section section-layout-wrap section-layout-wrap--hero">
                        {/* <div className="page-section page-section--layout_hero layout_hero layout_hero--type-extended" style={{ backgroundColor: '#121c40' }}></div> */}
                            <div className="page-section page-section--layout_hero layout_hero layout_hero--type-extended" >
                            <div className="section-content section-content--layout_hero">
                                <div className="page-section layout_hero row people-background" style={{marginBottom: '0px'}}>

                                    <div className="ratio-container">
                                        <div className="content">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="primary-content" style={{ opacity: 1 }}>
                                                        <div className="title text-center justify-self-start mb-5">
                                                            Can you win <br />the Constitution?   (📜, 📜) <br /> 
                                                            {/* <div className="hide-on-mobile" style={{color: 'red'}}>
                                                                (📜, 📜)
                                                            </div> */}

                                                            <div className="subtitle-title" style={{zIndex: '9999'}}>100% on-chain game to frontrun transactions</div>
                                                        </div>
                                                        {/* <h2 className style={{ textAlign: 'center', color: '#b11d18' }}>Can you win <br />the Constitution?</h2> */}
                                                        {/* <p className="font-console" style={{ textAlign: 'center', fontFamily: "BrokenConsole-Bold,Helvetica,sans-serif", color: '#b11d18', textDecoration: 'bold' }}>100% on-chain game to frontrun transactions.</p> */}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <MobileNav />
        </>
    );
}

export default Citadel;
