
import { Link } from 'react-router-dom';

function Header() {
    return (
        <div className="page-section page-section--header">
            <div className="section-content section-content--header">
                <div className="header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-2 col-md-2 col-xs-8">
                                <div className="site-logo">
                                    <h1><a href="/">The People Game</a></h1>
                                </div>

                            </div>
                            <div className="col-xl-10 col-md-10 col-xs-4 site-nav-container-col">

                                <a className="menu-toggle" href="#">
                                    <i className="bars">
                                        <span className="top" />
                                        <span className="mid" />
                                        <span className="bottom" />
                                    </i>
                                </a>
                                <div class="menu-wrap">

                                    <ul id="site-nav-primary" class="primary-nav" >

                                        <li>
                                            <a href="https://discord.com/invite/q4yEKSyTFY" target="_blank">
                                                <img src="./images/discord-icon.png" style={{ maxWidth: '25px' }}>
                                                </img>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/PeopleGameNFT" target="_blank">
                                                <img src="./images/twitter-icon.png" style={{ maxWidth: '25px' }}>
                                                </img>
                                            </a>
                                        </li>
                                        <li id="menu-item-18" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-18">

                                            <Link to="/whitepaper">
                                                Whitepaper
                                            </Link>
                                        </li>
                                        <li id="menu-item-19609" class="careers hide-submenu menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-19609">
                                            <Link to="/faq">
                                                FAQ
                                            </Link>
                                        </li>

                                        {/* <li id="menu-item-2134" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2134">
                                            <Link to="/tos">
                                                Terms and Conditions
                                            </Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Header;
