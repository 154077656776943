import { Link } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
function FAQ() {

    return (
        <body>
            <div id="root">

                <Header />
                <div className="w-full flex flex-col items-center md:h-screen" >
                    <div className="hidden md:block sliding" />
                    <div className="p-5 w-full flex flex-col items-center">
                   
                            <div className="title text-center justify-self-start mb-5" style={{ margin: '5rem', color: 'white' }}> </div>
                        <div className="flex flex-col md:flex-row justify-center items-center gap-10 w-full mb-5" style={{ maxWidth: '1500px' }}>
                            <div className="h-full w-full md:w-1/2 flex justify-between flex flex-col gap-5" style={{ width: '80%' }}>
                                <div className="w-full h-full relative flex p-1 md:p-5 flex justify-center " style={{ borderImage: 'url("/images/wood-frame-2.svg") 50 / 1 / 1 stretch', borderWidth: '30px', background: 'white' }}>
                                    {/* <div className="absolute" style={{ width: '120%', height: '120%', top: '-20px', left: '-20px', opacity: '0.08', backgroundImage: 'url("/images/wood-mask.svg")', backgroundRepeat: 'repeat', backgroundSize: '400px 268px' }} /> */}
                                    <div className="w-full h-full z-index:5 relative">
                                        <div className="flex flex-col items-center font-pixel gap-4 text-center">
                                            <div className="w-full flex justify-around items-center">
                                                <div className="underline text-red text-xl font-console">FAQ</div>
                                            </div>
                                            <div className="font-whitepaper">
                                                <b>Why was Constitution DAO your inspiration?</b> <br/>
                                                The story is legend... Wait for it...
                                                <a href="https://www.bloomberg.com/news/articles/2021-11-24/crypto-group-constitutiondao-to-shut-after-loss-to-ken-griffin">https://www.bloomberg.com/news/articles/2021-11-24/crypto-group-constitutiondao-to-shut-after-loss-to-ken-griffin</a>
                                            
                                            <br />
                                                <br />
                                            <b>Are you affiliated with Constitution DAO?</b>
                                            <br />
                                            No.
                                            <br />
                                            <br />
                                            <b>Wen Whitepaper:</b> <br/>
                                            <a href="https://medium.com/@thepeoplenftgame/the-people-game-whitepaper-54f808e0e6e9">https://medium.com/@thepeoplenftgame/the-people-game-whitepaper-54f808e0e6e9</a>
                                            <br />
                                            <br />
                                                <b>I read the whitepaper and I am still confused, will you have an easier explanation?</b>
                                                <br />
                                                Yes we are working on it.
                                                <br />
                                                <br />

                                                <b>What will set this project apart from Wolf Game, Wizards and Dragons, Bear Game, Fox Game, Whale Game, Fish Game, Vikings and Villagers, Gold Hunt Game, ETC?</b>
                                                <br />
                                                For starters our contract is not a straight copy, some of the games listed above still have "$WOOL" mentioned in their code. We have the same initial mechanics, however, our developers have worked to fix all the bugs that everyone is aware of on top of two other game breaking ones that we have uncovered in the whitepaper. 
                                                <br />
                                                The real differentiation between these games will come once the later phases come into play, our developers are proving that we can deliver from day 1, who will you trust to deliver the next phases?
                                                <br /><br/>
                                                <b>Wen Mint?</b>
                                                <br />
                                                This will be the subject of a further announcement, our Devs are making sure that everything is tidy before confirming a date. Don't worry it won't be a stealth mint, you'll have time to prepare.
                                                <br /><br />
                                                <b>Wen Whitelist?</b>
                                                <br />
                                                We are giving WL to the first 3000 Founding Fathers that join our !protest in our Discord.
   <br /><br />
                                                <b>Ok I have the @Founding Fathers (WL) now how do I give you my address?</b>
                                                <br />
                                                <ul>
                                                    <li>
                                                        Step 1: Go to #whitelist-sign-up channel on Discord
                                                    </li>
                                                    <li>
                                                        Step 2: Type "!signup WALLET_ADDRESS", replace WALLET_ADDRESS with your own 0x Ethereum wallet address, it won't accept ENS. 
                                                    </li>
                                                    <li>
                                                        Step 3: @Whitelist Father Bot will check that the formatting is correct and will let you know 
                                                    </li>
                                                    <li>
                                                        Step 4: Verify that your wallet has been registered correctly. If @Whitelist Father Bot has not replied to you, you probably have a typo in the !signup command, please try again 
                                                    </li>
                                                    <li>
                                                        Step 5: Done! 
                                                    </li>
                                                    <li>

                                                        Step 6: Don’t try to sign up 2 wallets, it’ll just update your previous one ;)

                                                    </li>
                                                </ul>
                                                <br /><br />
                                                <b>I got an NFT invite. What do I get?</b><br />
                                                The invitation NFT is an invite to join our Discord. You are not whitelisted until you join our Discord and call the whitelist bot, as stated in the question above.
                                    
                                                <br /><br />
                                                <b>Are there any differences between the @Founding Fathers (WL) role and the other roles such as @Chained Founding Fathers ?</b>
                                                No, the other roles are there to help us keep track of the activity in the more recent protests, if it's announced that the protest grants the Founding Father role, we will grant you the role in due time, it's a manual process, bare with us.

                                                <br /><br />
                                                <b>Who received the NFT invites and why?</b><br />
                                                The NFT invites were sent to original Constitution DAO investors and PVE gamers for being our inspiration.
                                                <br /><br />
                                                
                                                <b>How did you get my address? </b>
                                                <br />Well, we got it from the blockchain, every address that interacted with their contracts is public.<br/><br/>
                                                <b>
                                                    I was an original investor in CDAO, why did I not receive my invite?
                                                </b>
                                                
                                                <br />
                                                OpenSea hides Polygon airdrops by default, go to your profile, collection and check the hidden tab it should be there.

                                                <br/><br/><b>
                                                    Why can't I see the NFT invite? I did receive it.</b>
                                                <br />
                                                It's an OpenSea issue, you might need to refresh the MetaData manually, for this open the nft and click the grey refresh arrow in the top right.
                                                <br /><br />
                                                <b>Does the invite have any value? </b>
<br/>The invite is just that, an invitation to join our !Protest, they are non transferrable

                                                <br /><br /><b>Why are there 2 OpenSea Invites collections?</b>
                                                <br/>
Polygon was broken and we had to do 2 separate airdrops, we know it's confusing

                        
                                                </div>
                                            <div className="font-whitepaper">
                                              
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </body>
    );
}

export default FAQ;
