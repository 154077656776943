import React from 'react';
import Web3Provider, { Connectors } from 'web3-react';
import WalletConnectApi from '@walletconnect/web3-subprovider';
import AppProvider from './context';
import Citadel from './Citadel';
import RedirectPage from './components/RedirectPage';
import FAQ from './FAQ';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

const PROVIDER_URL = 'https://mainnet.infura.io/v3/ef885c57a7c8471bba0286cde4e589ba';

const { NetworkOnlyConnector, InjectedConnector, WalletConnectConnector } = Connectors;
const Network = new NetworkOnlyConnector({
  providerURL: PROVIDER_URL
});
const Injected = new InjectedConnector();

const WalletConnect = new WalletConnectConnector({
  api: WalletConnectApi,
  supportedNetworkURLs: {
    1: PROVIDER_URL
  },
  qrcode: true,
  bridge: "https://bridge.walletconnect.org", // Required
  defaultNetwork: 1
});

const connectors = { Network, Injected, WalletConnect };

function App() {
  
  return (
      <>
        <Web3Provider connectors={connectors} libraryName={'ethers.js'}>
            <AppProvider>
            <BrowserRouter>
            <Routes>
              <Route path="/" element={<Citadel />} />
              <Route path='/whitepaper' element={<RedirectPage/>}/>
              <Route path="/faq" element={<FAQ />} />
              <Route path="*" element={<Citadel />} />
              </Routes>
              </BrowserRouter>
            </AppProvider>
        </Web3Provider>
      </>
  );
}

export default App;
