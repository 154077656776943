
import { Link } from 'react-router-dom';

function MobileNav() {
    return (
        <div className="mobile-nav">
            <a className="menu-toggle" href="#">
                <i className="bars">
                    <span className="top" />
                    <span className="mid" />
                    <span className="bottom" />
                </i>
            </a>
            <ul id="site-nav-primary-mobile" class="primary-nav-mobile">
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-18" style={{ fontFamily: 'BrokenConsole-Bold,Helvetica,sans-serif' }}>
                    <a href="https://medium.com/@thepeoplenftgame/the-people-game-whitepaper-54f808e0e6e9" target="_blank">
                        Whitepaper
                    </a>
                </li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2134" style={{ fontFamily: 'BrokenConsole-Bold,Helvetica,sans-serif' }}>
                    <Link to="/FAQ">
                        FAQ
                    </Link>
                </li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2134" style={{ fontFamily: 'BrokenConsole-Bold,Helvetica,sans-serif' }}>
                    <a href="https://discord.com/invite/q4yEKSyTFY" target="_blank">
                        <img src="./images/discord.png" style={{ maxWidth: '25px' }}>
                        </img>
                    </a>
                </li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2134" style={{ fontFamily: 'BrokenConsole-Bold,Helvetica,sans-serif' }}>
                    <a href="https://twitter.com/PeopleGameNFT" target="_blank">
                        <img src="./images/twitter.png" style={{ maxWidth: '25px' }}>
                        </img>
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default MobileNav;